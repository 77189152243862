<template>
  <div>
    <link
      rel="resource"
      type="application/l10n"
      href="/es-viewer.properties.txt"
    >
    <b-row class="justify-content-md-center">
      <b-col cols="12">
        <b-card
          :title="$t('Reports.Analysis.title')"
        >
          <b-row
            class="d-flex flex-row"
          >
            <b-col
              cols="12"
              md="2"
              class="align-items-center justify-content-start"
            >
              <b-form-group
                :label="$t('Reports.Analysis.perPage.label')"
                label-for="perPageSelect"
                :description="$t('Reports.Analysis.perPage.description')"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  :options="pageOptions"
                />
              </b-form-group>
            </b-col>
            <!-- Datepicker Input Begin -->
            <b-col
              cols="6"
              md="2"
              class="align-items-center justify-content-start"
            >
              <b-form-group
                :description="$t('Reports.Analysis.fromDate.description')"
                :label="$t('Reports.Analysis.fromDate.label')"
                label-for="from-date-text"
                :invalid-feedback="$t('Reports.Analysis.fromDate.feedback')"
                :state="fromDateState()"
              >
                <b-input-group>
                  <b-form-input
                    id="from-date-text"
                    v-model="fromDateString"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    :state="fromDateState()"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="fromDateString"
                      :initial-date="fromInitialDate"
                      :max="maxDate"
                      button-only
                      right
                      :button-variant="fromDateOutlineVariant()"
                      size="sm"
                      aria-controls="from-date-text"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col
              cols="6"
              md="2"
              class="align-items-center justify-content-start"
            >
              <b-form-group
                :description="$t('Reports.Analysis.toDate.description')"
                :label="$t('Reports.Analysis.toDate.label')"
                label-for="to-date-string"
                :invalid-feedback="$t('Reports.Analysis.toDate.feedback')"
                :state="toDateState()"
              >
                <b-input-group>
                  <b-form-input
                    id="to-date-text"
                    v-model="toDateString"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    :state="toDateState()"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="toDateString"
                      :max="maxDate"
                      button-only
                      right
                      :button-variant="toDateOutlineVariant()"
                      size="sm"
                      aria-controls="to-date-text"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- Datepicker Input End -->
            <b-col
              cols="12"
              md="2"
              class="align-items-center justify-content-start"
            >
              <b-form-group
                :description="$t('Reports.Analysis.sort.description')"
                :label="$t('Reports.Analysis.sort.label')"
                label-for="sortBySelect"
              >
                <!-- TODO: Apply translation to this component-->
                <b-input-group>
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-75"
                  >
                    <template v-slot:first>
                      <option value="">
                        -- none --
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    :disabled="!sortBy"
                    class="w-25"
                  >
                    <option :value="false">
                      Asc
                    </option>
                    <option :value="true">
                      Desc
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              md="4"
              class="align-items-center justify-content-end"
            >
              <b-form-group
                :description="$t('Reports.Analysis.filter.description')"
                :label="$t('Reports.Analysis.filter.label')"
                label-for="filterInput"
              >
                <b-input-group>
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    :placeholder="$t('Reports.Analysis.filter.placeholder')"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      variant="outline-primary"
                      @click="filter = ''"
                    >
                      {{ $t('Reports.Analysis.filter.buttonText') }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-table
                striped
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template
                  #cell(response_type)="row"
                >
                  <b-badge
                    v-if="row.value!==0"
                    :variant="statuses[1][row.value]"
                  >
                    {{ statuses[0][row.value] }}
                  </b-badge>
                  <b-spinner
                    v-if="row.value===0"
                    class="mr-1"
                    variant="primary"
                  />
                </template>

                <template #cell(response)="row">
                  <b-button
                    v-if="row.value && row.item.action_type === 'ep_with_mod'"
                    v-b-modal.modal-mods
                    variant="flat-primary"
                    class="btn-icon rounded-circle"
                    @click="showMods(row.item)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <b-button
                    v-if="row.value && (row.item.action_type === 'eeud' || row.item.action_type === 'rhh')"
                    v-b-modal.modal-pdf
                    variant="flat-primary"
                    class="btn-icon rounded-circle"
                    @click="showPdf(row.item)"
                  >
                    <feather-icon icon="FileTextIcon" />
                  </b-button>
                  <!--b-icon-eye v-if="data.value"/-->
                </template>

              </b-table>
            </b-col>

            <b-col
              cols="12"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!-- PDF-related stuff Begin -->
      <b-modal
        id="modal-pdf"
        size="xl"
        hide-footer
        centered
        :title="currentPdfTitle"
      >
        <b-overlay
          id="overlay-pdf"
          :show="pdfOverlay.show"
          :variant="pdfOverlay.variant"
          :opacity="pdfOverlay.opacity"
          :blur="pdfOverlay.blur"
          rounded="sm"
        >
          <vue-pdf-app
            style="height: 90vh; width: 100%;"
            :pdf="currentPdfData"
            :file-name="currentPdfName"
          />
        </b-overlay>
      </b-modal>
      <b-modal
        id="modal-mods"
        size="lg"
        hide-footer
        centered
        :title="currentCompanyName"
      >
        <b-table
          small
          :fields="modifications.fields"
          :items="modifications.items"
        >
          <template #cell(fecha_pub)="data">
            {{ (new Date(Date.parse(data.value))).toLocaleString("es-CL", { year: 'numeric', month: 'long', day: 'numeric' }) }}
          </template>

          <template #cell(action)="row">
            <!--v-b-modal.modal-mods-->
            <b-button
              v-b-modal.modal-pdf
              variant="flat-primary"
              class="btn-icon rounded-circle"
              @click="showModPdf(row)"
            >
              <feather-icon icon="FileTextIcon" />
            </b-button>
          </template>
        </b-table>
      </b-modal>
      <!-- PDF-related stuff End -->
    </b-row>
  </div>
</template>

<script>

import {
  BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BBadge,
  BFormDatepicker, BOverlay,
} from 'bootstrap-vue'
import {
  startOfMonth, endOfToday, endOfDay, formatRFC3339, format, parse, isValid, isBefore,
} from 'date-fns'
// import VuePdfApp from 'vue-pdf-app'
import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormDatepicker,
    VuePdfApp: () => import(/* webpackPrefetch: true */ 'vue-pdf-app'),
    BOverlay,
  },
  props: {
    filterBy: {
      type: String,
      default: null,
    },
  },
  data() {
    const todaysEnd = endOfToday()
    const monthsStart = startOfMonth(new Date())
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: this.filterBy,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'email', label: this.$i18n.t('Reports.Analysis.table.fieldLabels.email') },
        { key: 'client_id', label: this.$i18n.t('Reports.Analysis.table.fieldLabels.clientID') },
        { key: 'response_type', label: this.$i18n.t('Reports.Analysis.table.fieldLabels.responseType') },
        { key: 'response', label: this.$i18n.t('Reports.Analysis.table.fieldLabels.response') },
      ],
      items: [
        // {
        //   email: 'dummy@dummy.cl',
        //   client_id: '99.999.999-9',
        //   response_type: this.$i18n.t('Reports.Analysis.table.responseTypes.rhh'),
        //   response: '<RHH>',
        // },
      ],
      // TODO: Apply translation to statuses' labels
      statuses: [{
        0: 'En Proceso',
        1: 'Empresa en un día',
        2: 'Con Modificaciones',
        3: 'Sin Modificaciones',
        4: 'Sin Conformidad',
        5: 'Requiere Ayuda',
        6: 'Con actividades',
        7: 'Sin actividades',
        8: 'Sin Modificaciones',
      },
      {
        1: 'light-success',
        2: 'light-info',
        3: 'light-dark',
        4: 'warning',
        5: 'light-primary',
        6: 'light-success',
        7: 'dark',
        8: 'light-dark',
      }],
      // PDF stuff
      pdfOverlay: {
        variant: 'transparent',
        opacity: 1.00,
        blur: '0.5em',
        show: true,
      },
      currentPdfData: '',
      currentPdfTitle: '',
      currentPdfName: '',
      currentJobId: 0,
      currentCompanyName: '',
      modifications: {
        fields: [
          { key: 'edicion', label: 'Edición' },
          { key: 'company_name', label: 'Nombre' },
          { key: 'fecha_pub', label: 'Publicación' },
          { key: 'action', label: 'Acción' },
        ],
        items: [],
      },
      // Date stuff
      toDateString: format(todaysEnd, 'yyyy-MM-dd'),
      fromDateString: format(monthsStart, 'yyyy-MM-dd'),
      fromInitialDate: monthsStart,
      maxDate: todaysEnd,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    toDateString: 'fetchWhenValid',
    fromDateString: 'fetchWhenValid',
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$http.get('/supervision/analysis', {
        params: {
          fromDate: formatRFC3339(this.fromDateAsDate()),
          toDate: formatRFC3339(this.toDateAsDate()),
        },
      })
        .then(response => {
          // console.log(response.data)
          this.items = response.data.entries
          this.totalRows = this.items.length
        })
        .catch(error => {
          console.log('There was an error:', error)
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    showModPdf(entry) {
      this.currentPdfData = ''
      this.pdfOverlay.show = true

      this.$http.get('/v2/ep-mod-pdf', {
        params: {
          id: this.currentJobId,
          index: entry.index,
        },
      }).then(response => {
        this.currentPdfData = `data:text/plain;base64,${response.data.data}`
        this.pdfOverlay.show = false
      }).catch(error => {
        console.log('There was an error:', error)
        this.pdfOverlay.show = false
        this.$swal({
          title: this.$i18n.t('SwAl.titles.error'),
          html: this.$i18n.t('SwAl.htmls.couldntLoadPdf', { error }),
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
    showMods(item) {
      this.currentCompanyName = item.name
      this.currentJobId = item.job_id
      this.$http.get('/v2/ep-mod', {
        params: {
          id: item.job_id,
        },
      }).then(response => {
        this.modifications.items = response.data.mods
      })
    },
    showPdf(item) {
      this.currentPdfTitle = item.name
      this.currentPdfName = `${item.job_id} - ${item.name.replace(/[^\w\s]/gi, '')}.pdf`
      this.currentPdfData = ''
      this.pdfOverlay.show = true
      this.$http.get('/v2/jobs/pdf', {
        params: {
          job_id: item.job_id,
          format: 'base64',
        },
      }).then(response => {
        this.currentPdfData = `data:text/plain;base64,${response.data.data}`
        this.pdfOverlay.show = false
      }).catch(error => {
        console.log('There was an error: ', error)
        this.pdfOverlay.show = false
      })
    },
    // Beginning of date-related functions
    fromDateAsDate() {
      return parse(this.fromDateString, 'yyyy-MM-dd', new Date())
    },
    toDateAsDate() {
      return endOfDay(parse(this.toDateString, 'yyyy-MM-dd', new Date()))
    },
    // Validator for From-Date datepicker
    fromDateState() {
      return isValid(this.fromDateAsDate()) ? null : false
    },
    // Validator for To-Date datepicker
    toDateState() {
      return isBefore(this.fromDateAsDate(), this.toDateAsDate()) ? null : false
    },
    fromDateOutlineVariant() {
      if (this.fromDateState() === false) {
        return 'outline-danger'
      }
      return 'outline-primary'
    },
    toDateOutlineVariant() {
      if (this.toDateState() === false) {
        return 'outline-danger'
      }
      return 'outline-primary'
    },
    fetchWhenValid() {
      // If both validators pass, fetch from backend
      if (this.fromDateState() === null && this.toDateState() === null) {
        this.fetchData()
      }
    },
    // End of date-related functions
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
